import { useState } from "react";
import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
	CardImg,
    CardBody,
    Input
} from "reactstrap";

import UseFooter from "components/Footers/Footer";
import UseHeader from "components/Headers/Header";
import showAlert from "components/Alert"
  
  const AccountDeletion = () => {

    const [phone, setPhone] = useState('')

    const deleteAccount = () => {
        if(phone == '') {
            showAlert('danger', <b>Please enter your phone number.</b>)
            return
        }
        showAlert('success', <b>Done! Please click on the link you will receive through text in 24 hours.</b>)
        setPhone('')
    }
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Delete Your Account</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container">
                    <div className="row justify-content-center py-4">
                        <div className="col-sm-12 col-md-5 mb-3">
                            <div className="card bg-dark border-0 mb-0">
                                <div className="card-body px-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <h2 className="text-light-gray h4">Request to Delete Account</h2>
                                        <p>We respect your privacy and understand you don't want your presence on bingeAsk. Please enter your registered number below and we will send you a deletion link in 24 hours. You can sign in before receiving link to cancel the deletion of your account.</p>
                                    </div>
                                    <div className="form-group mb-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-light-dark border-0"><i className="ni ni-mobile-button"></i></span>
                                            </div>
                                            <Input
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="form-control bg-light-dark border-0"
                                                placeholder="Enter phone number"
                                            />
                                        </div>
                                        <div className="text-center">
                                            <button type="button" className="btn btn-danger my-4" onClick={deleteAccount}>Request Deletion</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default AccountDeletion;
  